'use strict'

export const coinType = {
  BITCOIN: 'bitcoin',
  BITCOIN_TESTNET: 'bitcoin-testnet',
  ERC20: 'erc20',
  ERC20_KOVAN: 'erc20',
  ETHEREUM: 'ethereum',
  ETHEREUM_KOVAN: 'ethereum',
  MONACOIN: 'monacoin',
  MONACOIN_TESTNET: 'monacoin-testnet',
  RIPPLE: 'ripple',
  RIPPLE_TESTNET: '',
  RRC20: 'rrc20',
  RRC20_TESTNET: 'rrc20',
  RSK: 'rsk',
  RSK_TESTNET: 'rsk-testnet'
}

export const coinGroup = {
  BITCOIN: 'BITCOIN',
  BITCOIN_TESTNET: 'BTC-TESTNET',
  ERC20: 'ERC20',
  ERC20_KOVAN: 'ERC20_KOVAN',
  ETHEREUM: 'ETHEREUM',
  ETHEREUM_KOVAN: 'ETH-KOVAN',
  MONACOIN: 'MONACOIN',
  MONACOIN_TESTNET: 'MONA-TESTNET',
  RIPPLE: 'RIPPLE',
  RIPPLE_TESTNET: 'XRP-TESTNET',
  RRC20: 'RRC20',
  RRC20_TESTNET: 'RRC20-TESTNET',
  RSK: 'RSK',
  RSK_TESTNET: 'RSK-TESTNET'
}

export const coinName = {
  BITCOIN: 'BITCOIN',
  BITCOIN_TESTNET: 'BTC-TESTNET',
  ERC20: '',
  ERC20_KOVAN: '',
  ETHEREUM: 'ETHEREUM',
  ETHEREUM_KOVAN: 'ETH-KOVAN',
  MONACOIN: 'MONACOIN',
  MONACOIN_TESTNET: 'MONA-TESTNET',
  RIPPLE: 'RIPPLE',
  RIPPLE_TESTNET: 'XRP-TESTNET',
  RRC20: '',
  RRC20_TESTNET: '',
  RSK: 'RSK',
  RSK_TESTNET: 'RSK-TESTNET'
}

export const bitcoinTxType = {
  change: 'change',
  p2pk: 'p2pk',
  p2pkh: 'p2pkh',
  p2sh: 'p2sh'
}