'use strict'

import {
  coinType as dcentCoinType,
  coinGroup as dcentCoinGroup,
  coinName as dcentCoinName,
  bitcoinTxType as dcentBitcoinTxType
} from './dcent-web-type'
import {
  config as dcentConfig
} from './dcent-web-conf'

import Event from 'events'

const dcent = {}

// TimeOut Default Time 
var dcentCallTimeOutMs = dcentConfig.timeOutMs

dcent.messageRequestIdx = 0
dcent.messageResponsetIdx = 0

dcent.popupWindow = undefined

dcent.dcentWebDeferred = function () {
  let localResolve
  let localReject

  const promise = new Promise(async function (resolve, reject) {
    localResolve = resolve
    localReject = reject
  })

  return {
    resolve: localResolve,
    reject: localReject,
    promise
  }
}

dcent.dcentWebPromise = dcent.dcentWebDeferred()

dcent.dcentException = function (code, message) {
  let exception = {
    'header': {
      'version': '1.0',
      'request_from': 'dcent-web',
      'status': 'error'
    },
    'body': {
      'error': {
        'code': code,
        'message': message
      }
    }
  }
  return exception
}

dcent.dcentPopupWindow = async function () {
  // popup Open
  if (!dcent.popupWindow || dcent.popupWindow.closed) {
    //var strWindowFeatures = 'width=1600, height=900, left=200, top=0'
    //var strWindowFeatures = ''
    dcent.popupWindow = window.open('', '_blank')
    if (
      !dcent.popupWindow ||
      dcent.popupWindow.closed ||
      typeof dcent.popupWindow.closed === 'undefined'
    ) {
      return {
        header: {
          version: '1.0',
          response_from: 'api',
          status: 'error'
        },
        body: {
          error: {
            code: 'pop-up_blocked',
            message: 'Pop-up blocked. Please enable pop-up'
          }
        }
      }
    }
    if (dcent.popupWindow) {
      dcent.popupWindow.location.href = dcentConfig.popUpUrl
    }
    return null
  }
}

dcent._call = function (idx, params) {
  console.log('call', params)
  if (dcent.popupWindow) {
    var messageEvent = {
      idx: idx,
      event: 'BridgeRequest',
      type: 'json',
      payload: params
    }

    console.log('messageSend', messageEvent)

    dcent.popupWindow.postMessage(messageEvent,
      '*'
    )
  }
  // TODO : Error  Popup is not Opened.  
}

let ee = new Event.EventEmitter()

const getEventName = (idx) => {
  let eventName = ''
  eventName = 'Dcent.Event.' + idx
  return eventName
}

dcent.call = async function (params) {

  let idx = dcent.messageRequestIdx++

  return new Promise(async (resolve, reject) => {

    console.log('in Promise')
    if (!dcent.popupWindow || dcent.popupWindow.closed) {
      var result = await dcent.dcentPopupWindow()
      if (result !== null) {
        reject(result)
      }
    }

    dcent.dcentWebPromise.promise.then(async function () {
      console.log('dcentWebPromise - resolve')
      dcent._call(idx, params)
    })

    let popUpClosedListener = () => {
      console.log('Promise - reject')
      reject(dcent.dcentException('pop-up_closed', 'Pop-up windows has been closed'))
    }

    let timeOutCall = () => {
      ee.removeListener(getEventName(idx), eventEmitListener)
      ee.removeListener('popUpClosed', popUpClosedListener)
      reject(dcent.dcentException('time_out', 'The function execution time has expired'))
    }

    let timer = setTimeout(timeOutCall, dcentCallTimeOutMs)

    let eventEmitListener = (messageEvent) => {
      clearTimeout(timer)
      ee.removeListener('popUpClosed', popUpClosedListener)
      console.log('eventEmitter - emit')
      if (messageEvent.data.payload.header.status === "success") {
        resolve(messageEvent.data.payload)
      } else {
        reject(messageEvent.data.payload)
      }
    }

    ee.once('popUpClosed', popUpClosedListener)
    ee.once(getEventName(idx), eventEmitListener)

  })
}

dcent.messageReceive = function (messageEvent) {
  if (
    messageEvent.data.event === 'BridgeEvent' &&
    messageEvent.data.type === 'data' &&
    messageEvent.data.payload === 'popup-success'
  ) {
    console.log("messageReceive", messageEvent)
    dcent.dcentWebPromise.resolve()
    return
  }

  if (
    messageEvent.data.event === 'BridgeEvent' &&
    messageEvent.data.type === 'data' &&
    messageEvent.data.payload === 'popup-close'
  ) {
    console.log("messageReceive", messageEvent)
    dcent.popupWindow = undefined
    dcent.dcentWebPromise = dcent.dcentWebDeferred()
    ee.emit('popUpClosed')
    ee.removeAllListeners()
    return
  }

  if (
    messageEvent.data.event != 'BridgeResponse' ||
    messageEvent.data.type != 'json'
  ) {
    return
  }
  console.log("messageReceive", messageEvent)
  let idx = messageEvent.data.idx || dcent.messageResponsetIdx++

  ee.emit(getEventName(idx), messageEvent)

}

dcent.popupWindowClose = function () {
  if (dcent.popupWindow) {
    dcent.popupWindow.postMessage({
      event: 'BridgeEvent',
      type: 'data',
      payload: 'popup-close'
    },
      '*'
    )
  }
}

window.addEventListener('message', dcent.messageReceive, false)
window.addEventListener('beforeunload', dcent.popupWindowClose)

let isNumberString = (str) => {
  if (/^[0-9]+$/.test(str)) {
    return true
  }
  return false
};

let isHexNumberString = (str) => {
  if (/^(0x)?[0-9a-f]+$/.test(str.toLowerCase())) {
    // Check if it has the basic requirements of an address
    return true
  }
  return false
};

let checkParameter = (type, param) => {
  if (type === 'numberString') {
    if (typeof param !== 'string') throw dcent.dcentException('param_error', 'Invaild Parameter') // must string

    if (param.indexOf('0x', 0) === -1) {
      // number string
      if (isNumberString(param)) return param
    } else if (param.indexOf('0x', 0) === 0) {
      // hex string
      if (isHexNumberString(param)) return param
    }
    throw dcent.dcentException('param_error', 'Invaild Parameter')
  }

}

/**
 * Returns D'CENT Bridge (Tray Daemon) status infomation.
 * 
 * @returns {Object} that Bridge Status Infomation.  
 */
dcent.info = async function () {
  return await dcent.call({
    method: 'info'
  })
}

/**
 * Set the function execution timeout time. 
 * The default value is 60000ms, that is 1 minute.
 * 
 * @param {number} timeOutMs the timeout time that the function execution. The unit is `ms`
 * @returns {Object} void on success, excption on failure.
 */
dcent.setTimeOutMs = function (timeOutMs) {
  if (typeof timeOutMs !== 'number') {
    throw dcent.dcentException('param_error', 'timeOutMs is not Number Type')
  }
  dcentCallTimeOutMs = timeOutMs
}

/**
 * Returns D'CENT Biometric Wallet status infomation.
 * 
 * @returns {Object} that Wallet device information.
 */
dcent.getDeviceInfo = async function () {
  return await dcent.call({
    method: 'getDeviceInfo'
  })
}

/**
 * Set your label name to the D'CENT biometric Wallet. If you reboot your D'CENT, you can see the label name.
 * 
 * @param {string} label the label name of D'CENT biometric Wallet.
 * @returns {Object} set result. true, if you set completely otherwise false.
 */
dcent.setLabel = async function (label) {
  return await dcent.call({
    method: 'setLabel',
    params: {
      label: label
    }
  })
}

/**
 * Synchronize accounts between applications and Wallets. Call this function to add an account or update account information (for example, a balance or label).
 * 
 * @param {Object} accountInfos An array object for the account informations.
 * @returns {Object} sync result. true if you sync completely otherwise false.
 */
dcent.syncAccount = async function (accountInfos) {
  return await dcent.call({
    method: 'syncAccount',
    params: {
      accountInfos: accountInfos
    }
  })
}

/**
 * Returns current account list in Wallet
 * 
 * @returns {Object} account list
 */
dcent.getAccountInfo = async function () {
  return await dcent.call({
    method: 'getAccountInfo'
  })
}

/**
 * Returns Coin address value of all coin types using string value of key path.
 * 
 * @param {string} coinType coin type. 
 * @param {string} path string value of key path to get address
 * @returns {Object} address.
 */
dcent.getAddress = async function (coinType, path) {

  switch (coinType.toLowerCase()) {
    case dcentCoinType.BITCOIN.toLowerCase():
    case dcentCoinType.BITCOIN_TESTNET.toLowerCase():
    case dcentCoinType.ERC20.toLowerCase():
    case dcentCoinType.ERC20_KOVAN.toLowerCase():
    case dcentCoinType.ETHEREUM.toLowerCase():
    case dcentCoinType.ETHEREUM_KOVAN.toLowerCase():
    case dcentCoinType.MONACOIN.toLowerCase():
    case dcentCoinType.MONACOIN_TESTNET.toLowerCase():
    case dcentCoinType.RIPPLE.toLowerCase():
    case dcentCoinType.RIPPLE_TESTNET.toLowerCase():
    case dcentCoinType.RRC20.toLowerCase():
    case dcentCoinType.RRC20_TESTNET.toLowerCase():
    case dcentCoinType.RSK.toLowerCase():
    case dcentCoinType.RSK_TESTNET.toLowerCase():
      break
    default:
      throw dcent.dcentException('coin_type_error', 'not supported coin type')
  }

  return await dcent.call({
    method: 'getAddress',
    params: {
      coinType: coinType,
      path: path
    }
  })
}

/**
 * Returns Extended Public Key
 *
 * @param {string} key BIP44 Key Path that wants to extract public key. Must be hardened at least two depth of path. ex) m/44'/0'
 * @param {string} bip32name String to derivate BIP32 master key. Default value is "Bitcoin seed"
 * @returns {Object} XPUB
 */
dcent.getXPUB = async function (key, bip32name) {
  return await dcent.call({
    method: 'getXPUB',
    params: {
      key: key,
      bip32name: bip32name
    }
  })
}

/**
 * Returns a JSON Object for Bitcoin transaction.
 * @param {string} coinType coin type
 * @returns {Object} that D'CENT Bitcoin Transaction JSON Object.
 */
dcent.getBitcoinTransactionObject = async function (coinType) {
  switch (coinType.toLowerCase()) {
    case dcentCoinType.BITCOIN.toLowerCase():
    case dcentCoinType.BITCOIN_TESTNET.toLowerCase():
    case dcentCoinType.MONACOIN.toLowerCase():
    case dcentCoinType.MONACOIN_TESTNET.toLowerCase():
      break
    default:
      throw dcent.dcentException('coin_type_error', 'not supported coin type')
  }

  let message = {
    request: {
      header: {
        version: '1.0',
        request_to: ''
      },
      body: {
        command: '',
        parameter: {}
      }
    }
  }
  message.request.header.request_to = coinType
  message.request.body.command = 'transaction'
  message.request.body.parameter = {}
  message.request.body.parameter.version = 1
  message.request.body.parameter.locktime = 0

  return message

  // return await dcent.call({
  //   method: 'getBitcoinTransactionObject',
  //   params: {
  //     coinType: coinType
  //   }
  // })
}

/**
 *  previous transaction output information to be used. Max Number of “input” is 50.
 *
 * @param {Object} transaction JSON Object Returned from getBitCoinTransaction Function
 * @param {string} prevTx Previous full transaction data. This data will be used for generating previous transaction hash, and for check the amount of coin to be sent. Raw Transaction data with Hex formatted.
 * @param {number} utxoIdx index of transaction output from prev_tx
 * @param {string} type p2pkh | p2pk | p2sh 
 * @param {string} key key path for generating signature
 * @returns {Object} setting result. if you setting completely is true otherwise false.
 */
dcent.addBitcoinTransactionInput = async function (
  transaction,
  prevTx,
  utxoIdx,
  type,
  key
) {

  let parameter = transaction.request.body.parameter

  parameter.input = parameter.input || []

  parameter.input.push({
    prev_tx: prevTx,
    utxo_idx: utxoIdx,
    type: type,
    key: key
  })

  return transaction

  // return await dcent.call({
  //   method: 'addBitcoinTransactionInput',
  //   params: {
  //     transaction: transaction,
  //     prev_tx: prevTx,
  //     utxo_idx: utxoIdx,
  //     type: type,
  //     key
  //   }
  // })
}

/**
 * bitcoin spending information. Max number of “output” is 10.
 *
 * @param {Object} transaction JSON Object Returned from getBitCoinTransaction Function
 * @param {string} type p2pkh | p2pk | p2sh | change
 * @param {number} value amount of coin to send
 * @param {string} to address of receiver
 * @returns {Object} setting result. if you setting completely is true otherwise false.
 */
dcent.addBitcoinTransactionOutput = async function (
  transaction,
  type,
  value,
  to
) {
  let parameter = transaction.request.body.parameter

  parameter.output = parameter.output || []

  // // to is must array
  // if (typeof to != 'object') {
  //   throw 'to is must be object type'
  // }

  parameter.output.push({
    type: type,
    value: value,
    to: to
  })

  return transaction

  // return await dcent.call({
  //   method: 'addBitcoinTransactionOutput',
  //   params: {
  //     transaction: transaction,
  //     type: type,
  //     value: value,
  //     to: to
  //   }
  // })
}

/**
 * Returns signed transaction value. If you want to get sign value of "BITCOIN" or "MONACOIN" transaction, must call this function.
 *
 * @param {Object} transaction JSON Object Returned from getBitCoinTransaction Function
 * @returns {Object} signed transaction value
 */
dcent.getBitcoinSignedTransaction = async function (transaction) {
  if (transaction === null || typeof transaction === 'undefined') {
    throw dcent.dcentException('param_error', 'transaction object is undefined or null')
  } else {

    return await dcent.call({
      method: 'getBitcoinSignedTransaction',
      params: {
        transaction: transaction
      }
    })
  }
}

/**
 * Returns ethereum signed transaction. If you want to get sign value of "ETHEREUM" or "RSK" transaction, must call this function.
 *
 * @param {string} coinType coin type 
 * @param {string} nonce account nonce
 * @param {string} gasPrice GAS price
 * @param {string} gasLimit GAS limit 
 * @param {string} to recipient's address
 * @param {string} value amount of ether to be sent. ( WEI unit value )
 * @param {string} data transaction data (ex: "0x")
 * @param {string} key key path (BIP44)
 * @param {number} chainId chain id
 * @returns {Object} signed transaction value
 */
dcent.getEthereumSignedTransaction = async function (
  coinType,
  nonce,
  gasPrice,
  gasLimit,
  to,
  value,
  data,
  key,
  chainId
) {

  try {
    nonce = checkParameter('numberString', nonce)
    gasPrice = checkParameter('numberString', gasPrice)
    gasLimit = checkParameter('numberString', gasLimit)
    value = checkParameter('numberString', value)
  } catch (error) {
    throw error
  }

  if (typeof chainId !== 'number') {
    throw dcent.dcentException('param_error', 'Invaild Parameter')
  }

  switch (coinType.toLowerCase()) {
    case dcentCoinType.ETHEREUM.toLowerCase():
    case dcentCoinType.ETHEREUM_KOVAN.toLowerCase():
    case dcentCoinType.RSK.toLowerCase():
    case dcentCoinType.RSK_TESTNET.toLowerCase():
      break
    default:
      throw dcent.dcentException('coin_type_error', 'not supported coin type')
  }

  return await dcent.call({
    method: 'getEthereumSignedTransaction',
    params: {
      coinType: coinType,
      nonce: nonce,
      gas_price: gasPrice,
      gas_limit: gasLimit,
      to: to,
      value: value,
      data: data,
      key: key,
      chain_id: chainId
    }
  })
}

/**
 * Returns signed value of Token transaction. If you want to get sign value of "ERC20" or "RRC20" transaction, must call this function.
 *
 * @param {string} token token(coin) type of a transaction (`ERC20` or `RRC20`). coin type 
 * @param {string} nonce account nonce
 * @param {string} gasPrice GAS price
 * @param {string} gasLimit GAS limit 
 * @param {string} key key path (BIP44)
 * @param {number} chainId chain id
 * @param {Object} contract contract Address 
 * @returns {Object} signed transaction value
 */
dcent.getTokenSignedTransaction = async function (
  token,
  nonce,
  gasPrice,
  gasLimit,
  key,
  chainId,
  contract
) {

  try {
    nonce = checkParameter('numberString', nonce)
    gasPrice = checkParameter('numberString', gasPrice)
    gasLimit = checkParameter('numberString', gasLimit)
    contract.value = checkParameter('numberString', contract.value)
  } catch (error) {
    throw error
  }

  if (
    typeof chainId !== 'number' ||
    typeof contract.decimals !== 'number'
  ) {
    throw dcent.dcentException('param_error', 'Invaild Parameter')
  }

  switch (token.toLowerCase()) {
    case dcentCoinType.ERC20.toLowerCase():
    case dcentCoinType.ERC20_KOVAN.toLowerCase():
    case dcentCoinType.RRC20.toLowerCase():
    case dcentCoinType.RRC20_TESTNET.toLowerCase():
      break
    default:
      throw dcent.dcentException('coin_type_error', 'not supported token type')
  }

  return await dcent.call({
    method: 'getTokenSignedTransaction',
    params: {
      token: token,
      nonce: nonce,
      gas_price: gasPrice,
      gas_limit: gasLimit,
      key: key,
      chain_id: chainId,
      contract: contract
    }
  })
}

/**
 * Returns Signature value that sign the user message with the corresponding private key with the given key path (BIP32) 
 * 
 * @param {string} message Message to be Signed
 * @param {string} key string value of key path to get address
 * @returns {Object} ethereum Address & Signature for user message.
 */
dcent.getEthereumSignedMessage = async function (message, key) {
  return await dcent.call({
    method: 'getEthereumSignedMessage',
    params: {
      message: message,
      key: key
    }
  })
}

/* ripple */
/**
 * Returns ripple singed transaction. If you want to get sign value of "RIPPLE" transaction, must call this function.
 *
 * @param {string} fromAddress Source address of this ripple transaction
 * @param {string} fromPath Returns destination address of this transaction
 * @param {string} toAddress Destination address of this ripple transaction
 * @param {number} value Amount to be send ( Drops unit value)
 * @param {number} fee Fee of this transaction ( Drops unit value)
 * @param {number} sequence The sequence number, relative to the initiating account, of this transaction.
 * @param {string} destinationtag Destination tag of this transaction 
 * @param {number} flags Options. Default value is 0x80000000
 * @returns {Object} signed transaction value
 */
dcent.getRippleSignedTransaction = async function (
  fromAddress,
  fromPath,
  toAddress,
  value,
  fee,
  sequence,
  destinationtag,
  flags
) {

  flags = flags || 0x80000000 //Require a fully-canonical signature.

  return await dcent.call({
    method: 'getRippleSignedTransaction',
    params: {
      from_address: fromAddress,
      from_path: fromPath,
      to_address: toAddress,
      value: value,
      fee: fee,
      flags: flags,
      sequence: sequence,
      destinationtag: destinationtag,
      memo: ''
    }
  })
}

dcent.coinType = dcentCoinType
dcent.coinGroup = dcentCoinGroup
dcent.coinName = dcentCoinName
dcent.bitcoinTxType = dcentBitcoinTxType

const DcentWebApi = dcent

export default DcentWebApi // for nodejs

window.DcentWebApi = dcent // for inline script
window.DcentWebApi.coinType = dcentCoinType
window.DcentWebApi.coinGroup = dcentCoinGroup
window.DcentWebApi.coinName = dcentCoinName
window.DcentWebApi.bitcoinTxType = dcentBitcoinTxType